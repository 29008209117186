import React from 'react';
import { graphql } from 'gatsby';
import { PageProps } from '../types';
import { filterDataByLocale } from '@sprinklr/shared-lib/utils';
import MainPageTemplate from '../mainPage';

const NewsroomAllNewsPageTemplate: React.FC<PageProps> = props => {
  const { data, pageContext } = props;
  const { nodeLocale } = pageContext;
  const allNews = data?.newsData?.nodes;
  const customData = filterDataByLocale(allNews, nodeLocale, '');

  const passedProps = {
    ...props,
  };
  passedProps.customData = customData;
  passedProps.data = {
    contentfulPage: data.contentfulPage,
    site: data.site,
    headerV3: data.headerV3,
    footerV3: data.footerV3,
    allContentfulGlobalHeaderFooter: data.allContentfulGlobalHeaderFooter,
  };

  passedProps.pageContext = {
    ...pageContext,
    isGradientBackgroundEnabled: nodeLocale === 'en-US',
  };

  return <MainPageTemplate passedProps={passedProps} />;
};

export const newsroomAllNewsPageQuery = graphql`
  query NewsroomAllNewsPageSlug(
    $slug: String!
    $nodeLocale: String!
    $isHeaderV3Enabled: Boolean!
    $language: String!
  ) {
    ...HeaderFooterSocialQuery
    ...PageQuery
    ...LocalesQuery
    ...SiteMetadataQuery
    newsData: allContentfulNewsItem(
      sort: { fields: [publishedOn], order: [DESC] }
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      nodes {
        authorImage {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 70)
        }
        description {
          description
        }
        externalLink
        image {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 70)
        }
        nodeLocale: node_locale
        name
        isHighlighted
        publishedOn
        slug
        title
        type
        minutesToRead
        translations {
          languagesToRenderIn
        }
        additionalLanguagesToRenderIn
      }
    }
    sitePage {
      context {
        language
        navSlug
        nodeLocale
        slug
      }
    }
  }
`;

export default NewsroomAllNewsPageTemplate;
