/** @jsx jsx */

import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
import { Box, jsx } from 'theme-ui';
import loadable from '@loadable/component';
import {
  HeadAdditions,
  Layout,
  OrganizationSchema,
} from '@sprinklr/shared-lib';
import { reduceObjValues } from '@sprinklr/shared-lib/utils';
import GradientBackground from '@sprinklrjs/website-components/components/gradientBackground';
import { useAnchorTagListener } from '../../utils/useAnchorTagListener';
import TemplateRenderer from '../components/tempateRenderer/TemplateRenderer';
import { MainPageDataAttributes } from './constants';
import { PageProps } from './types';
import { getOGImageForProductCategory } from './utils/getOGImage';

const SinglePageHeader = loadable(
  () => import('../templates/singlePageHeader'),
);

const HeaderV2 = loadable(() => import('../templates/headerV2'));
const Footer = loadable(() => import('../templates/footerTemplate'));

const SocialLinksTemplate = loadable(
  () => import('../templates/socialLinksTemplate'),
);

const FooterV3 = loadable(() => import('../templates/footerV3'));
const HeaderV3 = loadable(() => import('../templates/headerV3'));

const SEO = loadable(() => import('@sprinklr/shared-lib/components/seo'));

const TEMPLATE_BOX_STYLES = {
  minHeight: '100vh',
};

const MainPage: React.FC<PageProps> = props => {
  useAnchorTagListener();
  const { passedProps } = props;
  const {
    path,
    data: {
      contentfulPage,
      site,
      headerV3,
      footerV3,
      minimalFooter,
      allContentfulGlobalHeaderFooter,
    },
    customData,
    pageContext,
  } = passedProps || props;

  const { header, footer, social } =
    allContentfulGlobalHeaderFooter?.edges[0]?.node ?? {};

  const {
    productCategory,
    shouldHideHeader,
    shouldHideSocialLinks,
    shouldHideFooter,
    isMinimal,
    pageContent,
    navSlug,
    metadata,
    translations,
    personalization,
    isHeaderCtasHidden,
  } = contentfulPage;

  const {
    nodeLocale,
    resolvedPathLocale,
    isGradientBackgroundEnabled = false,
  } = pageContext;

  const isPageRTL = nodeLocale === 'ar';

  const metaDataProps =
    !!metadata &&
    reduceObjValues(metadata, '', {
      exclude: ['twitterImage', 'openGraphImage'],
    });

  const singlePageTemplate = pageContent?.templates.find(
    template => template?.__typename === 'ContentfulTemplateSinglePageHeader',
  );

  const templates =
    pageContent?.templates
      .filter(
        template =>
          !isMinimal ||
          template?.__typename !== 'ContentfulTemplateSocialLinks',
      )
      .filter(
        template =>
          template?.__typename !== 'ContentfulTemplateSinglePageHeader',
      ) ?? [];

  const hasSubMenu =
    templates.filter(template => {
      return ['ContentfulTemplateSubnav', 'ContentfulTemplateSubMenu'].includes(
        template?.__typename,
      );
    }).length > 0;

  const fullContext = {
    ...pageContext,
    site,
    isHeaderMinimal: isMinimal,
    isPageRTL,
  };

  const thumbnailImageUrl = useMemo(
    () => getOGImageForProductCategory(productCategory?.[0]),
    [productCategory],
  );

  return (
    <Layout context={fullContext} path={path}>
      {isGradientBackgroundEnabled ? <GradientBackground /> : null}
      <OrganizationSchema path={path} />
      <HeadAdditions
        slug={navSlug}
        site={site}
        isPageRTL={isPageRTL}
        personalization={personalization}
      />
      {!!metaDataProps && (
        <SEO
          {...metaDataProps}
          site={site}
          domain={'https://www.sprinklr.com'}
          productCategory={productCategory || 'No Product Category'}
          slug={navSlug}
          translations={translations?.languagesToRenderIn}
          resolvedPath={resolvedPathLocale}
          thumbnailImageUrl={thumbnailImageUrl}
        />
      )}
      {headerV3 && !shouldHideHeader ? (
        <HeaderV3
          contentfulHeader={headerV3}
          contentfulPage={contentfulPage}
          isShadowEnabled={!hasSubMenu}
          pageContext={pageContext}
          isMinimal={isMinimal}
          site={site}
          translations={translations}
          isCtasHidden={isHeaderCtasHidden}
        />
      ) : null}
      {header && !shouldHideHeader ? (
        <HeaderV2
          contentfulHeader={header}
          contentfulPage={contentfulPage}
          isShadowEnabled={!hasSubMenu}
          pageContext={pageContext}
          isMinimal={isMinimal}
          site={site}
          translations={translations}
          isCtasHidden={isHeaderCtasHidden}
        />
      ) : null}
      {singlePageTemplate ? <SinglePageHeader {...singlePageTemplate} /> : null}
      <Box
        as="main"
        sx={TEMPLATE_BOX_STYLES}
        id="content"
        data-element-id={MainPageDataAttributes?.dataElementId}
        data-element-type={MainPageDataAttributes?.dataElementType}
      >
        <TemplateRenderer
          templates={templates}
          pageContext={pageContext}
          hasHeader={!shouldHideHeader}
          customData={customData}
          location={props.location}
        />
      </Box>
      {social && !shouldHideSocialLinks ? (
        <SocialLinksTemplate {...social} />
      ) : null}
      {footer && !shouldHideFooter ? (
        <Footer {...footer} isMinimal={isMinimal} />
      ) : null}
      {footerV3 && !shouldHideFooter ? (
        <FooterV3
          contentfulFooter={footerV3}
          minimalFooter={minimalFooter}
          isMinimal={isMinimal}
        />
      ) : null}
    </Layout>
  );
};

export const mainPageQuery = graphql`
  query ContentfulPagebySlug(
    $slug: String!
    $nodeLocale: String!
    $isHeaderV3Enabled: Boolean!
    $language: String!
  ) {
    ...HeaderFooterSocialQuery
    ...PageQuery
    ...LocalesQuery
    ...SiteMetadataQuery
    sitePage {
      context {
        language
        navSlug
        nodeLocale
        slug
      }
    }
  }
`;

export default MainPage;
