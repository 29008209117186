import { useEffect } from 'react';
import { isWindowDefined } from '@sprinklr/shared-lib/utils/isWindowDefined';

export const useAnchorTagListener = () => {
  useEffect(() => {
    let getAnchorTagAttempts = 0;
    if (isWindowDefined()) {
      const hash = window?.location?.hash;

      if (!!hash) {
        const anchorTagId = hash.substring(1);

        const attemptScrollToElement = () => {
          const attempting = setInterval(() => {
            const targetElement = document?.getElementById(anchorTagId);

            if (!!targetElement) {
              targetElement?.scrollIntoView();
              window.scrollBy({ top: -100 });
              clearInterval(attempting);
            }

            if (getAnchorTagAttempts === 40) clearInterval(attempting);
            else getAnchorTagAttempts = getAnchorTagAttempts + 1;
          }, 500);
        };
        attemptScrollToElement();
      }
    }
  }, []);
};
