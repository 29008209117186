import { DataAttributes } from '@sprinklr/shared-lib/@types/entities';

export const MainPageDataAttributes: DataAttributes = {
  dataElementId: 'page-template-tag-main-page',
  dataElementType: 'page-template-tag',
};

export const FeauturePageTemplateDataAttributes: DataAttributes = {
  dataElementId: 'template-tag-feature-page',
  dataElementType: 'template-tag',
};
