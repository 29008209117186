const SPRINKLR_SERVICE_OG_URL = 'https://www.sprinklr.com/spr-service.png';
const SPRINKLR_SOCIAL_OG_URL = 'https://www.sprinklr.com/spr-social.png';
const SPRINKLR_MARKETING_OG_URL = 'https://www.sprinklr.com/spr-marketing.png';
const SPRINKLR_INSIGHTS_OG_URL = 'https://www.sprinklr.com/spr-insights.png';

const ProductOGMapping = new Map<string, string>([
  ['Care', SPRINKLR_SERVICE_OG_URL],
  ['Engagement', SPRINKLR_SOCIAL_OG_URL],
  ['Marketing & Advertising', SPRINKLR_MARKETING_OG_URL],
  ['Research', SPRINKLR_INSIGHTS_OG_URL],
]);

const SlugOGMapping = new Map<string, string>([
  ['customer-service/', SPRINKLR_SERVICE_OG_URL],
  ['/social', SPRINKLR_SOCIAL_OG_URL],
  ['/marketing-and-advertising', SPRINKLR_MARKETING_OG_URL],
  ['/insights', SPRINKLR_INSIGHTS_OG_URL],
]);

export const getOGImageForProductCategory = (
  product: string | undefined | null,
) => {
  return ProductOGMapping.get(product);
};

export const getOGImageForProductSlug = (slug: string | undefined | null) => {
  return SlugOGMapping.get(slug);
};
